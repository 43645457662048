<template>
  <div>
  <follows-body v-if="connected"></follows-body>
    <connect-wallet v-else></connect-wallet>
  </div>
</template>

<script>

import {mapState} from "vuex";
import FollowDetail from "./followDetail";
import ConnectWallet from "../components/myself/connectWallet";
import FollowsBody from "../components/follows/followsBody";
import AddFollow from "../components/follows/addFollow";

export default {
  name: "follows",
  components: {AddFollow, FollowsBody, FollowDetail,ConnectWallet},
  computed:mapState(["totalSupply",'connected','userAddress']),
  async mounted(){

  },
  data(){
    return {
      index:0,
      items:[]
    }
  },
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped>

</style>