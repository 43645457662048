<template>
    <el-row class="tac">
      <el-col :span="3">

        <!--      我自己定义侧边栏 mother fuck-->
        <div class="hover:bg-gray-500 h-10 text-sm flex justify-center items-center border-r-2" :class="index==_index?'bg-gray-500':'bg-white'" v-for="(item,_index) in followsTokenIds" @click="index=_index">{{item.slice(0,7)}}...{{item.slice(38,42)}}</div>
\     </el-col>
      <el-col :span="21">
        <follow-detail :focus-on="followsTokenIds[index]"></follow-detail>
        <!--      <router-view></router-view>-->
      </el-col>
    </el-row>
</template>

<script>
import FollowDetail from "../../views/followDetail";
import ConnectWallet from "../myself/connectWallet";
import {mapState} from "vuex";

export default {
  name: "followsBody",
  components: {FollowDetail,ConnectWallet},
  computed:mapState(["totalSupply","followContract","connectTextContract","follows","followContract","followsTokenList","userAddress","followsTokenIds"]),
  async mounted(){
    // 进来的时候加载 follow 用户数据
    // 1.加载follows列表
    if(this.followsTokenIds.length===0){
      this.$store.state.followsTokenIds=await this.followContract.getAllFollows(this.userAddress)
    }
    // 2.查看所有的 follows 的内容,还有谁没有tokenList的给他添加上
    let followsTokenListkeys=Object.keys(this.followsTokenList)
    let restFollowTokenList=this.followsTokenIds.filter(world=>followsTokenListkeys.indexOf(world)===-1)
    for(let i of restFollowTokenList){
      let followTokenList=await this.connectTextContract.getTokenList(i)
      console.log(followTokenList)
      followTokenList=followTokenList[0].map(world=>world.toNumber())
      this.$store.state.followsTokenList[i]=followTokenList
    }
    // 接着就是加载剩下的，tokenURI了，使用myself中的tokenURI
    for(let i of this.followsTokenIds){
      console.log(this.follows,this.followsTokenList)
      if(this.follows[i]===undefined) this.$store.state.follows[i]=[]
      if(this.follows[i].length<this.followsTokenList[i].length){
        let restFollowTokenListMustGet=this.followsTokenList[i].slice(this.follows[i].length,this.followsTokenList[i].length)
        console.log(restFollowTokenListMustGet)
        for(let j of restFollowTokenListMustGet){
          console.log("j is:",j)
          let tokenURI=await this.connectTextContract.tokenURI(j)
          let result=this.parseData(tokenURI)
            result["tokenId"]=j
            // let poemFrom=(await this.connectTextContract.poemFrom(i)).toNumber()
            // result["poemFrom"]=poemFrom
            console.log(result)
            this.$store.state.follows[i].push(result)
        }
      }
    }
  },
  data(){
    return {
      index:0,
    }
  },
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    },
  }
}
</script>

<style scoped>

</style>