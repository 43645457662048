<template>
  <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" background-color="#545c64"
           text-color="#fff"
           active-text-color="#ffd04b" @select="handleSelect">
    <el-menu-item index="/homedisplay">Home</el-menu-item>
    <el-menu-item index="/follows">Follows</el-menu-item>
    <el-menu-item index="/create">Create</el-menu-item>
    <el-menu-item index="/myself">myself</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "elementHomePage",
  data() {
    return {
      activeIndex: '/homepage',
      // activeIndex2: '1'
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      // this.activeIndex=keyPath[0]
      this.$router.replace(keyPath[0])
    }

}
}
</script>

<style scoped>

</style>