<template>
  <div class="home">
<!--    <HomePage></HomePage>-->
    <element-home-page></element-home-page>
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to
import HomePage from "@/components/homePage/HomePage_simple";
import connectTextABI from "../abi/connectText.json"
import gsnABI from "../abi/gsn.json"
import followABI from "../abi/follow.json"
import {mapState} from "vuex";
import ElementHomePage from "../components/homePage/elementHomePage";
import {initVuexMetaMask} from "../assets/help/connect";

export default {
  name: 'Home',
  components: {
    ElementHomePage,
    // HelloWorld,
    HomePage
  },
  computed:mapState(["bscTestNet","connectTextAddress","gsnAddress",'followAddress']),
  async created(){

    let provider=new this.ethers.providers.JsonRpcProvider(this.bscTestNet)
    this.$store.state.provider=provider

    this.$store.state.gsnContract=new this.ethers.Contract(this.gsnAddress,gsnABI.abi,provider)

    this.$store.state.connectTextContract=new this.ethers.Contract(this.connectTextAddress,connectTextABI.abi,provider)
    // console.log((await this.$store.state.connectTextContract.totalSupply()).toNumber())

    this.$store.state.followContract=new this.ethers.Contract(this.followAddress,followABI.abi,provider)

    let providerJudgeConnect = new this.ethers.providers.Web3Provider(window.ethereum)
    let accountList=await providerJudgeConnect.listAccounts()

    if(accountList.length>0){

      console.log("is Connected!!!")
      await initVuexMetaMask.apply(this)
      console.log("初始化完成")

    }

    // let totalSupply=(await this.$store.state.connectTextContract.totalSupply()).toNumber()
    // console.log("totalSupply is:",totalSupply)
    // this.$store.state.totalSupply=totalSupply
  }
}
</script>
