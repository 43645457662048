import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    connected:false,
    provider:null,
    gsnContract:null,//合约
    connectTextContract:null,
    followContract:null,
    userAddress:null,
    signer:null,
    totalSupply:0,
    mymoney:-1,
    globalNFTs:[],
    myselfNFT:[],
    myselfNFTTokenIds:[],
    follows:{},// friends:[{},{}]
    inInit:true,
    followsTokenList:{},// friends:[tokenId]
    followsTokenIds:[],// address List
    bscTestNet:"https://polygon-rpc.com",
    // gsnAddress:"0xBa560FaeDbE4d7650978EBcDAA69AEA003f06918",
    // connectTextAddress:"0xc0ce118419522c36dF07eC55F00b9Dae0478596a"
    followAddress:"0xD1c721eD866aa03C1F0f5621553df1bb74c2284F",
    gsnAddress:"0x99FB57f8dB966c47bF55Eb11A1d7967e31C484b4",
    connectTextAddress:"0x029bB442D10c78Cc2b9E11E7cA4dD4D371Cee234"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
