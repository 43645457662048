<template>
  <div class="navbar mb-2 shadow-lg bg-blue-500 text-neutral-content">
    <div class="flex-none px-2 mx-2">
    <span class="text-lg font-bold">
            Connect
          </span>
    </div>
    <div class="flex-1 flex-row px-2 mx-2 justify-between flex">
      <div class="items-stretch hidden lg:flex">
        <a class="btn btn-ghost btn-sm rounded-btn" href="/homepage">
          home
        </a>
        <a class="btn btn-ghost btn-sm rounded-btn" href="/write_next">
          reWrite
        </a>
        <a class="btn btn-ghost btn-sm rounded-btn" href="/create">
          create
        </a>
        <a class="btn btn-ghost btn-sm rounded-btn" href="/myself">
          me
        </a>
      </div>
      <div class="pr-20 focus:flex-1">
        <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="searchData">
        </el-input>
      </div>
    </div>

    <div class="flex-none mr-12">
      <button class="btn btn-square btn-ghost">
        Login
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name:"homePage",
  data() {
    return {
      searchData:""
    }
  },
  methods: {
    onSearch(){

    }
  }
}
</script>