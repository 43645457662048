import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import follows from "../views/follows";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: "/",
        redirect: "/homedisplay"
      },
      {
        path:"/homedisplay",
        name:"homepage",
        component:()=>import("../views/HomePage")
      },

      {
        path:"/follows",
        name:"follows",
        component:()=>import('../views/follows'),
        children:[
          {
            path:"/hello",
            name:"hello",
            component:()=>import("../views/followDetail")
          }
        ]
      },
      {
        path:"/create",
        name:"create",
        component:()=>import("../views/create")
      },
      {
        path:"/about",
        name:"about",
        component:()=>import('../views/About')
      },
      {
        path:"/myself",
        name:"myself",
        component:()=>import("../views/myself")
      }
    ]
  },
  {
    path:"/write_next",
    name:"writeNext",
    component:()=>import("../views/WriteNext")
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
