export async function initVuexMetaMask(){
    handleEthereum()
    await getCurrentChain()

    let accounts=await window.ethereum.request({ method: 'eth_requestAccounts' });
    let provider=new this.ethers.providers.Web3Provider(window.ethereum)
    // console.log(provider,await provider.getSigner().getAddress())
    this.$store.state.provider=provider
    this.$store.state.signer=provider.getSigner()
    this.$store.state.userAddress=accounts[0]
    this.$store.state.connectTextContract=this.$store.state.connectTextContract.connect(provider.getSigner())
    await getUserFollows.call(this,accounts[0],provider)
    // this.$store.state.followContract=this.$store.state.followContract.connect(provider.getSigner())
    this.$store.state.totalSupply=(this.$store.state.connectTextContract.totalSupply()).toNumber
    this.$store.state.mymoney=(await this.$store.state.connectTextContract.balanceOf(accounts[0])).toNumber()
    this.$store.state.connected=true
}

async function getUserFollows(userAddress,provider){
    console.log(this.$store.state)
    this.$store.state.followContract=this.$store.state.followContract.connect(provider.getSigner())
    // 获取用户所有的tokenIds列表
    if(this.$store.state.followsTokenIds.length===0){
        this.$store.state.followsTokenIds=await this.$store.state.followContract.getAllFollows(userAddress)
    }
}

function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
        console.log('Ethereum successfully detected!');
        // Access the decentralized web!
    } else {
        window.alert('Please install MetaMask!');
    }
}

async function getCurrentChain(){

    const chainId = await window.ethereum.request({ method: 'eth_chainId' });

    if(parseInt(chainId,16)!==8001){
        try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x89' }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x89',
                                chainName: 'mumba',
                                rpcUrls: ['https://polygon-rpc.com'] /* ... */,
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
        }
    }
}