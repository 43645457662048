<template>
<!--  自己实现一个opensea的NFT展示卡牌-->
<!--  nft的属性有，name，description，attribute，nft的链上信息 引用了多少次-->
  <div @click="showAndReWrite" style="height: 17rem;width: 13rem" class="shadow-xl rounded overflow-hidden">
    <el-image style="width: 100%;height: 13rem" fit="cover" :src="imgData">
    </el-image>
    <div class="m-1.5">
      <div class="flex flex-row justify-between">
        <div class="font-sans text-xs subpixel-antialiased text-translate">{{name}}</div>
        <div class="font-sans text-xs subpixel-antialiased text-translate">#po {{tokenId}}</div>
      </div>
      <div class="mt-2 flex flex-row justify-end">
        <div class="font-sans subpixel-antialiased text-sm text-slate-500 mr-2"><i class="el-icon-apple"></i></div>
        <div class="font-sans subpixel-antialiased text-sm text-slate-500">0</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oneNFT",
  props:["name","tokenId","imgData"],

  methods:{
    showAndReWrite(){
      this.$router.push({path:"/write_next",query:{
        tokenId:this.tokenId
      }})
    }
  }
}
</script>

<style scoped>
.text-translate{
  color: rgba(105,105,105,0.5);
}
</style>