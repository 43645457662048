<template>
  <div class="flex flex-col items-center">
    <div class="text-4xl font-bold mt-10">Connect your wallet</div>
    <el-button class="mt-10 flex items-center bg-success w-3/12" @click="connectToMetamask">
      <img src="../../assets/public/metamask.svg" style="height: 30px;width: 30px"/>
      <span class="ml-4">metamask</span>
    </el-button>
  </div>
</template>

<script>
import {initVuexMetaMask} from "../../assets/help/connect";
import {mapState} from "vuex";
export default {
  name: "connectWallet",
  data() {
    return {
      inConnect:false
    }
  },
  computed:mapState(["connected","signer"]),
  methods:{
    async connectToMetamask(){
      console.log("in connected!!!",this.connected,this.inConnect)
      if(this.connected===false&&this.inConnect===false){
        this.inConnect=true
        await initVuexMetaMask.apply(this)
        this.inConnect=false
      }
    }
  }
}
</script>

<style scoped>

</style>