<template>
<div>
  <div class="inline-block px-4 rounded-full bg-cyan-600 text-sm" @click="follow" :class="isFollowed?'bg-slate-600 border border-current text-black outline-1':'bg-blue-600 text-white'" type="primary">{{isFollowed?"取消关注":"加关注"}}</div>
</div>
</template>

<script>
import {mapState} from "vuex";
import follows from "../../views/follows";

export default {
  name: "addFollow",
  props:["address"],
  computed:{
    ...mapState(["followsTokenIds",'followContract']),
    isFollowed(){
      if(this.followsTokenIds.length===0) return false
      let lowerCaseFollowsTokenId = this.followsTokenIds.map(world=>world?.toLowerCase())
      return lowerCaseFollowsTokenId.indexOf(this.address.toLowerCase())!==-1
    }
  },
  methods:{
    async follow(){
      let receipt=""
      if(this.isFollowed===true){
        // 如果是已经follow的那就burn掉
        receipt=await this.followContract.burn(this.address)
      }else{
        receipt=await this.followContract.mint(this.address)
      }
      this.$message({
        message: `congratulation your transactionHash ${this.isFollowed?"取消关注":"加关注"} success and transactionHash is:`+receipt?.hash,
        type: 'success'
      });
      // console.log("follow the ohm",this.address,this.followsTokenIds[1],this.followsTokenIds?.[1]?.toLowerCase()===this.address.toLowerCase())
    }
  }
}
</script>

<style scoped>

</style>