<template>
<div>
  <OneNFT :name="item['name']" :img-data="item['image']" :token-id="item.tokenId" class="ml-8 mt-3 float-left" v-for="item in follows[focusOn]"></OneNFT>
</div>
</template>

<script>
import OneNFT from "../components/NFTDisplay/oneNFT";
import {mapState} from "vuex";
export default {
  name: "followDetail",
  components: {OneNFT},
  props:["focusOn"],
  computed:mapState(["totalSupply","followContract","connectTextContract","follows","followContract","followsTokenList","userAddress","followsTokenIds"]),
  data(){
    return {
      address:""
    }
  },
  methods:{
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    },
  },
  created(){
    if(this.follows[this.focusOn]===undefined){
      this.follows[this.focusOn]=[]
    }
  },
  updated() {

  },
  async beforeUpdate() {
    console.log(this.follows,this.followsTokenList)
    // if(this.follows[this.focusOn].length<this.followsTokenList[this.focusOn].length){
      // for(let i=this.follows[this.focusOn].length;i<this.followsTokenList[this.focusOn].length;i++){
      //   let tokenURI=await this.connectTextContract.tokenURI(this.followsTokenList[i])
      //   let result=this.parseData(tokenURI)
      //   result["tokenId"]=i
      //   // let poemFrom=(await this.connectTextContract.poemFrom(i)).toNumber()
      //   // result["poemFrom"]=poemFrom
      //   console.log(result)
      //   this.$store.state.follows[this.focusOn].push(result)
      // }
    // }
  }
}
</script>

<style scoped>

</style>